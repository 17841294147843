.page-link {
  color: #6c757d !important;
}

.page-link:hover {
  background-color: #ececf6 !important;
}

.active .page-link {
  color: #ffffff !important;
  background-color: #6c757d !important;
  border-color: #6c757d !important;
}

.disabled .page-link {
  color: #abb1b6 !important;
}

.card-title {
  margin-bottom: 0 !important;
}

h5,
.h5 {
  margin-bottom: 0 !important;
}

.pagination {
  margin-bottom: 0 !important;
}
